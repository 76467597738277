// Fade-in on scroll

$(function () {
  $(window).scroll(function () {
    $("section").each(function (i) {
      var bottom_of_object = $(this).position().top + $(this).outerHeight();
      var bottom_of_window = $(window).scrollTop() + $(window).height();

      /* Adjust the "300" to either have a delay or that the content starts fading a bit before you reach it  */
      bottom_of_window = bottom_of_window + 1000;

      if (bottom_of_window > bottom_of_object) {
        $(this).animate({ opacity: "1" }, 1000);
      }
    });
  });
});


// Custom cursor

$(document).ready(function(){
var cursor = $(".cursor");

  $(window).mousemove(function(e) {
    cursor.css({
      top: e.clientY - cursor.height() / 2,
      left: e.clientX - cursor.width() / 2
    });
  });

  $(window)
  .mouseleave(function() {
    cursor.css({
      opacity: "0"
    });
  })
  .mouseenter(function() {
    cursor.css({
      opacity: "1"
    });
  });

  $(".link-hover")
  .mouseenter(function() {
    cursor.css({
      transform: "scale(3.2)"
    });
  })
  .mouseleave(function() {
    cursor.css({
      transform: "scale(1)"
    });
  });

  $(window)
  .mousedown(function() {
    cursor.css({
      transform: "scale(.2)"
    });
  })
  .mouseup(function() {
    cursor.css({
      transform: "scale(1)"
    });
  });
});

// Flash banner

const cookieStored = {
  getItem: (key) => {
    const cookies = document.cookie
      .split(";")
      .map((cookie) => cookie.split("="))
      .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
    // Each cookie in cookie string mapped into object
    return cookies[key];
  },
  setItem: (key, value) => {
    document.cookie = `${key}=${value}`;
  },
};

const storageType = cookieStored;
const consentPropertyId = "oxdynamics_flash";

// // helper functions
// const shouldShowPopup = () => !storageType.getItem(consentPropertyId);
// const saveToStorage = () => storageType.setItem(consentPropertyId, true);

// const flashBanner = document.getElementById("flash-banner");
// const dismiss = document.getElementById("dismiss");

// const acceptFn = (event) => {
//   saveToStorage(storageType);
//   flashBanner.classList.add("hidden");
// };

// dismiss.addEventListener("click", acceptFn);

// if (shouldShowPopup(storageType)) {
//   flashBanner.classList.remove("hidden");
// }


// Cookies

const cookieStorage = {
  getItem: (key) => {
    const cookies = document.cookie
      .split(";")
      .map((cookie) => cookie.split("="))
      .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
    // Each cookie in cookie string mapped into object
    return cookies[key];
  },
  setItem: (key, value) => {
    document.cookie = `${key}=${value}`;
  },
};

// const storageType = cookieStorage;
const consentPropertyName = "oxdynamics_session";

// helper functions
const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
const saveToStorage = () => storageType.setItem(consentPropertyName, true);

// trigger action when page is loaded
window.onload = () => {
  const consentPopup = document.getElementById("cookie-consent");
  const acceptBtn = document.getElementById("accept");

  const acceptFn = (event) => {
    saveToStorage(storageType);
    consentPopup.classList.add("hidden");
  };

  acceptBtn.addEventListener("click", acceptFn);

  if (shouldShowPopup(storageType)) {
    consentPopup.classList.remove("hidden");
  }
};
